import { APIURL } from './components/Utility/APIBase'
import axios, { AxiosInstance } from 'axios'
import router from '@/router'
import AuthService from './services/AuthService'

const apiClient: AxiosInstance = axios.create({
  baseURL: APIURL,
  headers: {
    'Content-type': 'application/json; charset=utf-8'
  }
})

apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('apitoken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''

  return config
})

apiClient.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  if (error.response !== undefined) {
    console.log(error.response.data)

    const errorCheckType = false // true = check only for invalid token error message, false = check by performing an API count request to a specific endpoint.
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const userdata = localStorage.getItem('user')
    const token = localStorage.getItem('apitoken')
    let doRouterPushFlag = true

    if (error.response && error.response.status === 401) {
      // store.dispatch('logout')
      // router.push('/login')
      // Check for whether user is a guest or not and try to do the appropriate thing concerning the site login / logoff and redirection.
      if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === false.toString() && userdata !== undefined && userdata !== null && userdata.startsWith('{"id":1,')) {
        if (errorCheckType && error.response.data && error.response.data.message.toString().toLowerCase().includes('invalid token')) {
          // console.log('[apiClient.interceptors] Invalid token detected in the response from the API. Doing a logout.')
          AuthService.logout(true)
          doRouterPushFlag = false
        } else {
          try {
            // The polled API endpoint must not be accessible for the public user role when doing count GET requests, but must be accessible for the authenticated user role.
            const response = await axios.get(APIURL + '/ekstern-services/count', { headers: { 'Content-type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` } })
          } catch (error2) {
            // let message
            if (axios.isAxiosError(error2) && error2.response) {
              // message = error.response.data.message
              if (error2.response.status === 401) {
                // console.log('[apiClient.interceptors] API usergroup access check failed. Doing a logout.')
                AuthService.logout(true)
                doRouterPushFlag = false
              }
            }
          }
        }

        if (doRouterPushFlag) {
          router.push({ path: '/medlemsportal/login' })
            .then(() => { router.go(0) })
        }
      } else if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString()) {
        AuthService.logout()
        router.push({ path: '/medlemsportal/login' })
          .then(() => { router.go(0) })
        // window.location.reload()
      } else {
        AuthService.logout()
      }
    }
  }

  return Promise.reject(error)
})

export default apiClient
