class LocalStorageHelpers {
  public removeAllFromLS () : void {
    this.removeUserLoginDataFromLS()
  }

  public removeUserLoginDataFromLS () : void {
    localStorage.removeItem('user')
    localStorage.removeItem('apitoken')
    localStorage.removeItem('status')
    localStorage.removeItem('logintype')
  }

  public resetUserLoginDataInLS () : void {
    localStorage.removeItem('user')
    localStorage.removeItem('apitoken')
    localStorage.setItem('status', false.toString())
    localStorage.setItem('logintype', false.toString())
  }

  public getFromLSTheCounter () : number | null {
    const retVal = localStorage.getItem('counter')

    if (retVal === undefined || retVal === null) {
      return null
    }

    return Number(retVal)
  }

  public setInLSTheCounter (counterVal: number | null) : any {
    if (counterVal === null) {
      localStorage.removeItem('counter')
    } else {
      localStorage.setItem('counter', (counterVal === null ? '' : counterVal.toString()))
    }
  }

  public getFromLSTheStatus () : boolean | null {
    const retVal = localStorage.getItem('status')

    if (retVal === undefined || retVal === null) {
      return null
    }

    return Boolean(retVal)
  }

  public setInLSTheStatus (statusVal: boolean | null) : void {
    if (statusVal === null) {
      localStorage.removeItem('status')
    } else {
      localStorage.setItem('status', (statusVal === null ? '' : statusVal.toString()))
    }
  }

  public getFromLSTheLogintype () : boolean | null {
    const retVal = localStorage.getItem('logintype')

    if (retVal === undefined || retVal === null) {
      return null
    }

    return Boolean(retVal)
  }

  public setInLSTheLogintype (logintypeVal: boolean | null) : void {
    if (logintypeVal === null) {
      localStorage.removeItem('logintype')
    } else {
      localStorage.setItem('logintype', (logintypeVal === null ? '' : logintypeVal.toString()))
    }
  }

  public getFromLSTheAPIToken () : string | null {
    const retVal = localStorage.getItem('apitoken')

    if (retVal === undefined || retVal === null) {
      return null
    }

    return String(retVal)
  }

  public setInLSTheAPIToken (apitokenVal: string | null) : void {
    if (apitokenVal === null) {
      localStorage.removeItem('apitoken')
    } else {
      localStorage.setItem('apitoken', (apitokenVal === null ? '' : apitokenVal.toString()))
    }
  }

  public getFromLSTheUser () : any | null {
    const retVal = localStorage.getItem('user')

    if (retVal === undefined || retVal === null) {
      return null
    }

    return retVal
  }

  public setInLSTheUser (userObjVal: any | null) : void {
    if (userObjVal === null) {
      localStorage.removeItem('user')
    } else {
      localStorage.setItem('user', (userObjVal === null ? '' : userObjVal.toString()))
    }
  }
}

export default new LocalStorageHelpers()
