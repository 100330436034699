// import Vue from "vue";
// import Vuex from "vuex";
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import LocalStorageHelpers from '@/components/Utility/LocalStorageHelpers'

// import Auth from "./modules/auth.module"

// Vue.use(Vuex)

/* export default new Vuex.Store({
  modules: {
    Auth
  }
}) */

export interface State {
  counter: number | null
  apitoken: string | null
  status: boolean | null // false = login failed, true = login succeeded
  logintype: boolean | null // false = guest user, true = normal user
  user: string | null
}

export const key: InjectionKey<Store<State>> = Symbol('Default app store')

// export default createStore({
export const store = createStore<State>({
  state: {
    counter: 0,
    apitoken: '',
    status: false,
    logintype: false,
    user: ''
  },
  getters: {
    getLoginSysStateCounter (state) {
      return state.counter
    },
    getLoginSysStateAPIToken (state) {
      return state.apitoken
    },
    getLoginSysStateStatus (state) {
      return state.status
    },
    getLoginSysStateLogintype (state) {
      return state.logintype
    },
    getLoginSysStateUser (state) {
      return state.user
    }
  },
  mutations: {
    setLoginSysStateCounter (state, payload) {
      state.counter = payload
    },
    setLoginSysStateAPIToken (state, payload) {
      state.apitoken = payload
    },
    setLoginSysStateStatus (state, payload) {
      state.status = payload
    },
    setLoginSysStateLogintype (state, payload) {
      state.logintype = payload
    },
    setLoginSysStateUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    loadStoredState (context) {
      context.commit('setLoginSysStateCounter', LocalStorageHelpers.getFromLSTheCounter())
      context.commit('setLoginSysStateAPIToken', LocalStorageHelpers.getFromLSTheAPIToken())
      context.commit('setLoginSysStateStatus', LocalStorageHelpers.getFromLSTheStatus())
      context.commit('setLoginSysStateLogintype', LocalStorageHelpers.getFromLSTheLogintype())
      context.commit('setLoginSysStateUser', LocalStorageHelpers.getFromLSTheUser())
    },
    storeCurrentState (context) {
      LocalStorageHelpers.setInLSTheCounter(context.state.counter)
      LocalStorageHelpers.setInLSTheStatus(context.state.status)
      LocalStorageHelpers.setInLSTheLogintype(context.state.logintype)
      LocalStorageHelpers.setInLSTheAPIToken(context.state.apitoken)
      LocalStorageHelpers.setInLSTheUser(context.state.user)
    }
  },
  modules: {
  }
})

// define your own `useStore` composition function
export function useStore () : any {
  return baseUseStore(key)
}

export default store
