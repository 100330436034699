import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { store, key } from './store'
import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'
import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import './assets/css/main.css'
// import socketio from 'socket.io-client'
// import * as Ably from 'ably'

import theHeader from '@/components/Frontend/Header/index.vue'
import theSidebar from '@/components/Frontend/Sidebar/index.vue'
import adminSidebar from '@/components/theAdminSidebar.vue'
import theFooter from '@/components/Frontend/Footer/index.vue'
import MobileSidebar from '@/components/Frontend/HeaderMobile/index.vue'
// declare global {
//   interface Window {
//     ably: any
//   }
// }
// window.ably = new Ably.Realtime({ key: process.env.VUE_APP_ABLY_KEY })

createApp(App).use(store, key).use(router).use(Equal).use(createVuestic()).component('front-header', theHeader).component('front-sidebar', theSidebar).component('front-footer', theFooter).component('admin-sidebar', adminSidebar).component('mobileSidebar', MobileSidebar).mount('#app')
// createApp(App).use(store, key).use(router).use(Equal).use(createVuestic()).provide('$socket', new VueSocketIO({ debug: true, connection: SocketInstance })).component('front-header', theHeader).component('front-sidebar', theSidebar).component('front-footer', theFooter).component('admin-sidebar', adminSidebar).component('mobileSidebar', MobileSidebar).mount('#app')
// createApp({ extends: App, created () { this.$store.dispatch('loadStoredState') } }).use(store, key).use(router).use(Equal).use(createVuestic()).component('front-header', theHeader).component('front-sidebar', theSidebar).component('front-footer', theFooter).component('admin-sidebar', adminSidebar).component('mobileSidebar', MobileSidebar).mount('#app')
