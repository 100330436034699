import { Options, Vue } from 'vue-class-component'
import { forgotPwAPIURL } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { Menus } from '@/services/MenusDataService'
import SysMenu, { MenuTypePlacementFriendlyType, SysMenuMenuType } from '@/types/SysMenu'
import AuthService from '@/services/AuthService'
import { Watch } from 'vue-property-decorator'
import axios from 'axios'
import store from '@/store'

type dataReturnType = { menus: any; error: any; }

export default class theSidebar extends Vue {
  $Message: any
  error: any = null
  private menus: SysMenu[] = []
  // private menutypes = SysMenuMenuType[] = []
  private currentListSortingOrder = 'menu_position:asc'
  forgottenPasswordModal = false
  actionConfirmationModal = false
  actionConfirmationMessage = ''
  emailString = ''

  data () {
    return {
      value: false
    }
  }

  @Watch('forgottenPasswordModal')
  onPassModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.emailString = ''
    }
  }

  @Watch('actionConfirmationModal')
  onActionModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.actionConfirmationMessage = ''
    }
  }

  public async newPasswordRequest () : Promise<void> {
    if (this.emailString !== undefined && this.emailString !== null && this.emailString.toString().trim().length >= 3 && this.emailString.toString().includes('@')) {
      axios.post(forgotPwAPIURL, {
        email: this.emailString.toString().trim()
      })
        .then((response) => {
          console.log('Email with link sent: ' + response.statusText)
          this.forgottenPasswordModal = false
          this.actionConfirmationModal = true
          this.actionConfirmationMessage = 'Vi har sendt en email til dig med et link hvor du kan �ndre din adgangskode'
        })
        .catch((err) => {
          console.log(err)
          this.actionConfirmationModal = true
          this.actionConfirmationMessage = 'Der opstod en fejl: ' + err
        })
    }
  }

  // Remove the comments here, as well as in the html to enable submenus
  // public subMenu (menuId : number) {
  //   const subMenuTitel : any = []
  //   for (const subMenuItem of this.menus) {
  //     if (subMenuItem.menu_submenu_id === menuId && subMenuItem.menu_status === true && subMenuItem.menu_submenu === true) {
  //       subMenuTitel.push(subMenuItem)
  //     }
  //   }
  //   return subMenuTitel
  // }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public navigationRouter (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : void {
    console.log(url)
    CommonFunctions.navigationRouter(url, optionalPath1, optionalPath2, optionalPath3)

    // if (url.toLowerCase().includes('http') || url.toLowerCase().includes('www')) {
    //   window.open(url, 'noopener')

    //   return url
    // }
    // if (url === '#') {
    //   return url
    // }
    // if (url === 'club-list') {
    //   this.$router.push('/klubber')

    //   return url
    // }
    // if (url === 'member-list') {
    //   this.$router.push('/medlemmer')

    //   return url
    // }
    // if (url === 'member-registration') {
    //   this.$router.push('/medlemmer/nyt-medlem')

    //   return url
    // }
    // if (url === 'competition-list') {
    //   this.$router.push('/staevner')

    //   return url
    // }
    // if (url === 'event-list') {
    //   this.$router.push('/arrangementer')

    //   return url
    // }
    // if (url === 'sponsor-list') {
    //   this.$router.push('/sponsorer')

    //   return url
    // }
    // if (url === 'news-archive') {
    //   this.$router.push('/nyheder')

    //   return url
    // }
    // if (url === 'ranking-main') {
    //   this.$router.push({ path: '/staevner/ranglister' })

    //   return url
    // }
    // if (url === 'the-calendar') {
    //   this.$router.push({ path: '/kalender' })

    //   return url
    // }
    // if (url === 'tournaments') {
    //   this.$router.push({ name: 'FrontEndTournaments' })
    //   return url
    // } else {
    //   console.log(url)

    //   this.$router.push({ name: 'Pages', params: { pathSlug1: this.convertToSlug(optionalPath1), pathSlug2: this.convertToSlug(optionalPath2), pathSlug3: this.convertToSlug(optionalPath3), slug: url } })

    //   return url
    // }
  }

  // public retrieveMenuTypes () : void {
  //   console.log('Fetching menu types data from API.')

  //   Menus.MenuTypesDataService.getAll()
  //     .then((response) => {
  //       this.menutypes = response.data
  //       console.log(response.data)
  //     })
  //     .catch((err) => {
  //       this.error = err
  //     })
  // }

  public retrieveMenus () : void {
    Menus.MenusDataService.getAll(this.currentListSortingOrder, null, 'menu_status=true&menu_type_id.menu_type_placering=Sidebar&menu_type_id.domaene_id=1')
      .then((response) => {
        this.menus = response.data
        this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public logOut () : void {
    AuthService.logout(true)
  }

  // Changes the button to a logout button if false
  public currentlyLoggedIn () : boolean {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      return true
    }
    return false
  }

  public userLoggedIn () {
    return store.state.logintype
  }

  async mounted () : Promise<void> {
    // this.retrieveMenuTypes()
    this.retrieveMenus()
  }
}
