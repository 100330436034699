import { Options, Vue } from 'vue-class-component'
// import { Component, Watch} from 'vue-property-decorator'
import { APIURL } from '@/components/Utility/APIBase'
import BaseFunctions from '@/components/Utility/Base'
import { Posts } from '@/services/PostsDataService'
import SponsorsDataService from '@/services/SponsorsDataService'
import SysPost, { SysPostCategory, SysPostVersion } from '@/types/SysPost'
import SysSponsor from '@/types/SysSponsor'
// import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import Router from 'vue-router'
import { store } from '@/store/index'
import AuthService from '@/services/AuthService'

type dataReturnType = { APIURL: string; sponsors: any; indlaegs: any; indlaegversionering: any; indlaegkategori: any; error: any; }

@Options({
  props: {
    instanceType: Number
  }
  // components: {
  //   QuillEditor
  // }
})

export default class Home extends Vue {
  instanceType!: number
  private indlaegs: SysPost[] = []
  private sponsors: SysSponsor[] = []
  private indlaegversionerings: SysPostVersion[] = []
  private indlaegkategoris: SysPostCategory[] = []
  private indlaegversionering = {} as SysPostVersion
  private indlaegkategori = {} as SysPostCategory
  error: any = null
  private currentListSortingOrder = 'indlaeg_udgivet:desc'
  private tempPost = {} as SysPost
  public homeCounterMaxThreshold = 42
  toDaysDate = new Date().toISOString()

  readonly name : string = 'Home'
  data (): dataReturnType {
    return {
      APIURL: APIURL,
      indlaegs: this.indlaegs,
      indlaegversionering: this.indlaegversionering,
      indlaegkategori: this.indlaegkategori,
      error: this.error,
      sponsors: this.sponsors
    }
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public convertHTMLToTextShort (htmlData: string, cutoffSize = 85) : string {
    const getVal = this.convertHTMLToText(htmlData).trim()

    return getVal.slice(0, cutoffSize) + (getVal.length > cutoffSize + 1 ? ' ...' : '')
  }

  public getIndexOfPostVersion (postVersion: number, singlePostEntryObj: SysPost = this.tempPost) : number {
    // const retVal = this.indlaegs.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    const retVal = singlePostEntryObj.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    // console.log('Index of post version = ' + retVal + ' ; post versioning object = ' + JSON.stringify(singlePostEntryObj.indlaeg_versionerings_id))

    return (retVal === undefined ? -1 : retVal)
  }

  public retrievePosts () : void {
    console.log('Fetching post data from API.')
    Posts.PostsDataService.getAll(this.currentListSortingOrder, { slicemode: 0, start: 0, limit: 20, page: 0, pagesize: 16, totalcount: 4 }, `indlaeg_type=0&indlaeg_status=true&indlaeg_udgivet_lt=${this.toDaysDate}`)
      .then((response) => {
        this.indlaegs = response.data
        // console.log(response.data)
        this.forceRerenderPostsList()
        this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public forceRerenderPostsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public retrieveSponsors () : void {
    console.log('Fetching sponsors from API.')
    SponsorsDataService.getAll('', null, 'sponsor_status=true&sponsor_forside=true')
      .then((response) => {
        this.sponsors = response.data
        this.forceRerenderSponsorsList()
        this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public forceRerenderSponsorsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  async mounted () : Promise<void> {
    this.retrievePosts()
    this.retrieveSponsors()

    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString()) {
      // Logout if user is the guest user in order to get token refreshed as it expires after some time.
      if (loginType === undefined || loginType === null || loginType === false.toString() || userdata === undefined || userdata === null || userdata.startsWith('{"id":1,')) {
        if (loginType === undefined || loginType === null || userdata === undefined || userdata === null) {
          AuthService.logout(false)
          localStorage.setItem('counter', '0')

          // console.log('AUTOMATIC LOGOUT!')
        } else {
          const homeCounter = localStorage.getItem('counter')
          const cntr = (homeCounter === undefined || homeCounter === null ? 0 : Number(homeCounter) + 1)

          if (cntr >= this.homeCounterMaxThreshold) {
            AuthService.logout(false)
            localStorage.setItem('counter', '0')

            // console.log('AUTOMATIC LOGOUT!')
          } else {
            localStorage.setItem('counter', cntr.toString())
          }
        }
      }
    }
  }
}
