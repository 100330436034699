import router from '@/router'
import { APIURL } from './APIBase'

export const defaultImageURL = APIURL
export const defaultInvoiceURL = APIURL
export const defaultInvitationURL = APIURL
export const defaultClubId = 22 // Gæsteklubben id
export const defaultLicenseId = 353 // Product player license id
export const defaultJuniorLicenseId = 354 // Product junior player license id
export const defaultClubLicenseId = 345 // Product club license id
export const defaultMainTournamentId = 3
export const defaultDailyTournamentId = 4
export const defaultOldBoys35Id = 5
export const defaultOldBoys35WestId = 13
export const defaultOldBoys50Id = 6
export const defaultJuniorTournamentSpringId = 7
export const defaultJuniorTournamentFallId = 14
export const defaultWomenPairsId = 8
export const defaultParadartTournamentId = 16

/* eslint-disable */
export const countryList: { code: string, code3: string, name: string, number: string, phoneCode: string,  }[] = [
	{code: "AF", code3: "AFG", name: "Afghanistan", number: "004", phoneCode: "+93"},
	{code: "AL", code3: "ALB", name: "Albania", number: "008", phoneCode: "+355"},
	{code: "DZ", code3: "DZA", name: "Algeria", number: "012", phoneCode: "+213"},
	{code: "AS", code3: "ASM", name: "American Samoa", number: "016", phoneCode: "+1-684"},
	{code: "AD", code3: "AND", name: "Andorra", number: "020", phoneCode: "+376"},
	{code: "AO", code3: "AGO", name: "Angola", number: "024", phoneCode: "+244"},
	{code: "AI", code3: "AIA", name: "Anguilla", number: "660", phoneCode: "+1-264"},
	{code: "AQ", code3: "ATA", name: "Antarctica", number: "010", phoneCode: "+"},
	{code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028", phoneCode: "+1-268"},
	{code: "AR", code3: "ARG", name: "Argentina", number: "032", phoneCode: "+54"},
	{code: "AM", code3: "ARM", name: "Armenia", number: "051", phoneCode: "+374"},
	{code: "AW", code3: "ABW", name: "Aruba", number: "533", phoneCode: "+297"},
	{code: "AU", code3: "AUS", name: "Australia", number: "036", phoneCode: "+61"},
	{code: "AT", code3: "AUT", name: "Austria", number: "040", phoneCode: "+43"},
	{code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031", phoneCode: "+994"},
	{code: "BS", code3: "BHS", name: "Bahamas (the)", number: "044", phoneCode: "+1-242"},
	{code: "BH", code3: "BHR", name: "Bahrain", number: "048", phoneCode: "+973"},
	{code: "BD", code3: "BGD", name: "Bangladesh", number: "050", phoneCode: "+880"},
	{code: "BB", code3: "BRB", name: "Barbados", number: "052", phoneCode: "+1-246"},
	{code: "BY", code3: "BLR", name: "Belarus", number: "112", phoneCode: "375"},
	{code: "BE", code3: "BEL", name: "Belgium", number: "056", phoneCode: "+32"},
	{code: "BZ", code3: "BLZ", name: "Belize", number: "084", phoneCode: "+501"},
	{code: "BJ", code3: "BEN", name: "Benin", number: "204", phoneCode: "+229"},
	{code: "BM", code3: "BMU", name: "Bermuda", number: "060", phoneCode: "+1-441"},
	{code: "BT", code3: "BTN", name: "Bhutan", number: "064", phoneCode: "+975"},
	{code: "BO", code3: "BOL", name: "Bolivia (Plurinational State of)", number: "068", phoneCode: "+591"},
	{code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535", phoneCode: "+599"},
	{code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070", phoneCode: "+387"},
	{code: "BW", code3: "BWA", name: "Botswana", number: "072", phoneCode: "+267"},
	{code: "BV", code3: "BVT", name: "Bouvet Island", number: "074", phoneCode: "+"},
	{code: "BR", code3: "BRA", name: "Brazil", number: "076", phoneCode: "+55"},
	{code: "IO", code3: "IOT", name: "British Indian Ocean Territory (the)", number: "086", phoneCode: "+246"},
	{code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096", phoneCode: "+673"},
	{code: "BG", code3: "BGR", name: "Bulgaria", number: "100", phoneCode: "+359"},
	{code: "BF", code3: "BFA", name: "Burkina Faso", number: "854", phoneCode: "+226"},
	{code: "BI", code3: "BDI", name: "Burundi", number: "108", phoneCode: "+257"},
	{code: "CV", code3: "CPV", name: "Cabo Verde", number: "132", phoneCode: "+238"},
	{code: "KH", code3: "KHM", name: "Cambodia", number: "116", phoneCode: "+855"},
	{code: "CM", code3: "CMR", name: "Cameroon", number: "120", phoneCode: "+237"},
	{code: "CA", code3: "CAN", name: "Canada", number: "124", phoneCode: "+1"},
	{code: "KY", code3: "CYM", name: "Cayman Islands (the)", number: "136", phoneCode: "+1-345"},
	{code: "CF", code3: "CAF", name: "Central African Republic (the)", number: "140", phoneCode: "+236"},
	{code: "TD", code3: "TCD", name: "Chad", number: "148", phoneCode: "+235"},
	{code: "CL", code3: "CHL", name: "Chile", number: "152", phoneCode: "+56"},
	{code: "CN", code3: "CHN", name: "China", number: "156", phoneCode: "+86"},
	{code: "CX", code3: "CXR", name: "Christmas Island", number: "162", phoneCode: "+61"},
	{code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands (the)", number: "166", phoneCode: "+61"},
	{code: "CO", code3: "COL", name: "Colombia", number: "170", phoneCode: "+57"},
	{code: "KM", code3: "COM", name: "Comoros (the)", number: "174", phoneCode: "+269"},
	{code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", number: "180", phoneCode: "+242"},
	{code: "CG", code3: "COG", name: "Congo (the)", number: "178", phoneCode: "+243"},
	{code: "CK", code3: "COK", name: "Cook Islands (the)", number: "184", phoneCode: "+682"},
	{code: "CR", code3: "CRI", name: "Costa Rica", number: "188", phoneCode: "+506"},
	{code: "HR", code3: "HRV", name: "Croatia", number: "191", phoneCode: "+385"},
	{code: "CU", code3: "CUB", name: "Cuba", number: "192", phoneCode: "+53"},
	{code: "CW", code3: "CUW", name: "Curaçao", number: "531", phoneCode: "+599"},
	{code: "CY", code3: "CYP", name: "Cyprus", number: "196", phoneCode: "+357"},
	{code: "CZ", code3: "CZE", name: "Czechia", number: "203", phoneCode: "+420"},
	{code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384", phoneCode: "+225"},
	{code: "DK", code3: "DNK", name: "Denmark", number: "208", phoneCode: "+45"},
	{code: "DJ", code3: "DJI", name: "Djibouti", number: "262", phoneCode: "+253"},
	{code: "DM", code3: "DMA", name: "Dominica", number: "212", phoneCode: "+1-767"},
	{code: "DO", code3: "DOM", name: "Dominican Republic (the)", number: "214", phoneCode: "+1-809"},
	{code: "EC", code3: "ECU", name: "Ecuador", number: "218", phoneCode: "+593"},
	{code: "EG", code3: "EGY", name: "Egypt", number: "818", phoneCode: "+20"},
	{code: "SV", code3: "SLV", name: "El Salvador", number: "222", phoneCode: "+503"},
	{code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226", phoneCode: "+240"},
	{code: "ER", code3: "ERI", name: "Eritrea", number: "232", phoneCode: "+291"},
	{code: "EE", code3: "EST", name: "Estonia", number: "233", phoneCode: "+372"},
	{code: "SZ", code3: "SWZ", name: "Eswatini", number: "748", phoneCode: "+268"},
	{code: "ET", code3: "ETH", name: "Ethiopia", number: "231", phoneCode: "+251"},
	{code: "FK", code3: "FLK", name: "Falkland Islands (the) [Malvinas]", number: "238", phoneCode: "+500"},
	{code: "FO", code3: "FRO", name: "Faroe Islands (the)", number: "234", phoneCode: "+298"},
	{code: "FJ", code3: "FJI", name: "Fiji", number: "242", phoneCode: "+679"},
	{code: "FI", code3: "FIN", name: "Finland", number: "246", phoneCode: "+358"},
	{code: "FR", code3: "FRA", name: "France", number: "250", phoneCode: "+33"},
	{code: "GF", code3: "GUF", name: "French Guiana", number: "254", phoneCode: "+594"},
	{code: "PF", code3: "PYF", name: "French Polynesia", number: "258", phoneCode: "+689"},
	{code: "TF", code3: "ATF", name: "French Southern Territories (the)", number: "260", phoneCode: "+"},
	{code: "GA", code3: "GAB", name: "Gabon", number: "266", phoneCode: "+241"},
	{code: "GM", code3: "GMB", name: "Gambia (the)", number: "270", phoneCode: "+220"},
	{code: "GE", code3: "GEO", name: "Georgia", number: "268", phoneCode: "+995"},
	{code: "DE", code3: "DEU", name: "Germany", number: "276", phoneCode: "+49"},
	{code: "GH", code3: "GHA", name: "Ghana", number: "288", phoneCode: "+233"},
	{code: "GI", code3: "GIB", name: "Gibraltar", number: "292", phoneCode: "+350"},
	{code: "GR", code3: "GRC", name: "Greece", number: "300", phoneCode: "+30"},
	{code: "GL", code3: "GRL", name: "Greenland", number: "304", phoneCode: "+299"},
	{code: "GD", code3: "GRD", name: "Grenada", number: "308", phoneCode: "+1-473"},
	{code: "GP", code3: "GLP", name: "Guadeloupe", number: "312", phoneCode: "+590"},
	{code: "GU", code3: "GUM", name: "Guam", number: "316", phoneCode: "+1-671"},
	{code: "GT", code3: "GTM", name: "Guatemala", number: "320", phoneCode: "+502"},
	{code: "GG", code3: "GGY", name: "Guernsey", number: "831", phoneCode: "+44-1481"},
	{code: "GN", code3: "GIN", name: "Guinea", number: "324", phoneCode: "+224"},
	{code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624", phoneCode: "+245"},
	{code: "GY", code3: "GUY", name: "Guyana", number: "328", phoneCode: "+592"},
	{code: "HT", code3: "HTI", name: "Haiti", number: "332", phoneCode: "+509"},
	{code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334", phoneCode: "+"},
	{code: "VA", code3: "VAT", name: "Holy See (the)", number: "336", phoneCode: "+379"},
	{code: "HN", code3: "HND", name: "Honduras", number: "340", phoneCode: "+504"},
	{code: "HK", code3: "HKG", name: "Hong Kong", number: "344", phoneCode: "+852"},
	{code: "HU", code3: "HUN", name: "Hungary", number: "348", phoneCode: "+36"},
	{code: "IS", code3: "ISL", name: "Iceland", number: "352", phoneCode: "+354"},
	{code: "IN", code3: "IND", name: "India", number: "356", phoneCode: "+91"},
	{code: "ID", code3: "IDN", name: "Indonesia", number: "360", phoneCode: "+62"},
	{code: "IR", code3: "IRN", name: "Iran (Islamic Republic of)", number: "364", phoneCode: "+98"},
	{code: "IQ", code3: "IRQ", name: "Iraq", number: "368", phoneCode: "+964"},
	{code: "IE", code3: "IRL", name: "Ireland", number: "372", phoneCode: "+353"},
	{code: "IM", code3: "IMN", name: "Isle of Man", number: "833", phoneCode: "+44-1624"},
	{code: "IL", code3: "ISR", name: "Israel", number: "376", phoneCode: "+972"},
	{code: "IT", code3: "ITA", name: "Italy", number: "380", phoneCode: "+39"},
	{code: "JM", code3: "JAM", name: "Jamaica", number: "388", phoneCode: "+1-876"},
	{code: "JP", code3: "JPN", name: "Japan", number: "392", phoneCode: "+81"},
	{code: "JE", code3: "JEY", name: "Jersey", number: "832", phoneCode: "+44-1534"},
	{code: "JO", code3: "JOR", name: "Jordan", number: "400", phoneCode: "+962"},
	{code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398", phoneCode: "+7"},
	{code: "KE", code3: "KEN", name: "Kenya", number: "404", phoneCode: "+254"},
	{code: "KI", code3: "KIR", name: "Kiribati", number: "296", phoneCode: "+686"},
	{code: "KP", code3: "PRK", name: "Korea (the Democratic People's Republic of)", number: "408", phoneCode: "+850"},
	{code: "KR", code3: "KOR", name: "Korea (the Republic of)", number: "410", phoneCode: "+82"},
	{code: "KW", code3: "KWT", name: "Kuwait", number: "414", phoneCode: "+965"},
	{code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417", phoneCode: "+996"},
	{code: "LA", code3: "LAO", name: "Lao People's Democratic Republic (the)", number: "418", phoneCode: "+856"},
	{code: "LV", code3: "LVA", name: "Latvia", number: "428", phoneCode: "+371"},
	{code: "LB", code3: "LBN", name: "Lebanon", number: "422", phoneCode: "+961"},
	{code: "LS", code3: "LSO", name: "Lesotho", number: "426", phoneCode: "+266"},
	{code: "LR", code3: "LBR", name: "Liberia", number: "430", phoneCode: "+231"},
	{code: "LY", code3: "LBY", name: "Libya", number: "434", phoneCode: "+218"},
	{code: "LI", code3: "LIE", name: "Liechtenstein", number: "438", phoneCode: "+423"},
	{code: "LT", code3: "LTU", name: "Lithuania", number: "440", phoneCode: "+370"},
	{code: "LU", code3: "LUX", name: "Luxembourg", number: "442", phoneCode: "+352"},
	{code: "MO", code3: "MAC", name: "Macao", number: "446", phoneCode: "+853"},
	{code: "MG", code3: "MDG", name: "Madagascar", number: "450", phoneCode: "+261"},
	{code: "MW", code3: "MWI", name: "Malawi", number: "454", phoneCode: "+265"},
	{code: "MY", code3: "MYS", name: "Malaysia", number: "458", phoneCode: "+60"},
	{code: "MV", code3: "MDV", name: "Maldives", number: "462", phoneCode: "+960"},
	{code: "ML", code3: "MLI", name: "Mali", number: "466", phoneCode: "+223"},
	{code: "MT", code3: "MLT", name: "Malta", number: "470", phoneCode: "+356"},
	{code: "MH", code3: "MHL", name: "Marshall Islands (the)", number: "584", phoneCode: "+692"},
	{code: "MQ", code3: "MTQ", name: "Martinique", number: "474", phoneCode: "+596"},
	{code: "MR", code3: "MRT", name: "Mauritania", number: "478", phoneCode: "+222"},
	{code: "MU", code3: "MUS", name: "Mauritius", number: "480", phoneCode: "+230"},
	{code: "YT", code3: "MYT", name: "Mayotte", number: "175", phoneCode: "+262"},
	{code: "MX", code3: "MEX", name: "Mexico", number: "484", phoneCode: "+52"},
	{code: "FM", code3: "FSM", name: "Micronesia (Federated States of)", number: "583", phoneCode: "+691"},
	{code: "MD", code3: "MDA", name: "Moldova (the Republic of)", number: "498", phoneCode: "+373"},
	{code: "MC", code3: "MCO", name: "Monaco", number: "492", phoneCode: "+377"},
	{code: "MN", code3: "MNG", name: "Mongolia", number: "496", phoneCode: "+976"},
	{code: "ME", code3: "MNE", name: "Montenegro", number: "499", phoneCode: "+382"},
	{code: "MS", code3: "MSR", name: "Montserrat", number: "500", phoneCode: "+1-664"},
	{code: "MA", code3: "MAR", name: "Morocco", number: "504", phoneCode: "+212"},
	{code: "MZ", code3: "MOZ", name: "Mozambique", number: "508", phoneCode: "+258"},
	{code: "MM", code3: "MMR", name: "Myanmar", number: "104", phoneCode: "+95"},
	{code: "NA", code3: "NAM", name: "Namibia", number: "516", phoneCode: "+264"},
	{code: "NR", code3: "NRU", name: "Nauru", number: "520", phoneCode: "+674"},
	{code: "NP", code3: "NPL", name: "Nepal", number: "524", phoneCode: "+977"},
	{code: "NL", code3: "NLD", name: "Netherlands (the)", number: "528", phoneCode: "+31"},
	{code: "NC", code3: "NCL", name: "New Caledonia", number: "540", phoneCode: "+687"},
	{code: "NZ", code3: "NZL", name: "New Zealand", number: "554", phoneCode: "+64"},
	{code: "NI", code3: "NIC", name: "Nicaragua", number: "558", phoneCode: "+505"},
	{code: "NE", code3: "NER", name: "Niger (the)", number: "562", phoneCode: "+227"},
	{code: "NG", code3: "NGA", name: "Nigeria", number: "566", phoneCode: "+234"},
	{code: "NU", code3: "NIU", name: "Niue", number: "570", phoneCode: "+683"},
	{code: "NF", code3: "NFK", name: "Norfolk Island", number: "574", phoneCode: "+672"},
	{code: "MP", code3: "MNP", name: "Northern Mariana Islands (the)", number: "580", phoneCode: "+1-670"},
	{code: "NO", code3: "NOR", name: "Norway", number: "578", phoneCode: "+47"},
	{code: "OM", code3: "OMN", name: "Oman", number: "512", phoneCode: "+968"},
	{code: "PK", code3: "PAK", name: "Pakistan", number: "586", phoneCode: "+92"},
	{code: "PW", code3: "PLW", name: "Palau", number: "585", phoneCode: "+680"},
	{code: "PS", code3: "PSE", name: "Palestine, State of", number: "275", phoneCode: "+970"},
	{code: "PA", code3: "PAN", name: "Panama", number: "591", phoneCode: "+507"},
	{code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598", phoneCode: "+675"},
	{code: "PY", code3: "PRY", name: "Paraguay", number: "600", phoneCode: "+595"},
	{code: "PE", code3: "PER", name: "Peru", number: "604", phoneCode: "+51"},
	{code: "PH", code3: "PHL", name: "Philippines (the)", number: "608", phoneCode: "+63"},
	{code: "PN", code3: "PCN", name: "Pitcairn", number: "612", phoneCode: "+870"},
	{code: "PL", code3: "POL", name: "Poland", number: "616", phoneCode: "+48"},
	{code: "PT", code3: "PRT", name: "Portugal", number: "620", phoneCode: "+351"},
	{code: "PR", code3: "PRI", name: "Puerto Rico", number: "630", phoneCode: "+1-787"},
	{code: "QA", code3: "QAT", name: "Qatar", number: "634", phoneCode: "+974"},
	{code: "MK", code3: "MKD", name: "Republic of North Macedonia", number: "807", phoneCode: "+389"},
	{code: "RO", code3: "ROU", name: "Romania", number: "642", phoneCode: "+40"},
	{code: "RU", code3: "RUS", name: "Russian Federation (the)", number: "643", phoneCode: "+7"},
	{code: "RW", code3: "RWA", name: "Rwanda", number: "646", phoneCode: "+250"},
	{code: "RE", code3: "REU", name: "Réunion", number: "638", phoneCode: "+262"},
	{code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652", phoneCode: "+590"},
	{code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", number: "654", phoneCode: "+290"},
	{code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659", phoneCode: "+1-869"},
	{code: "LC", code3: "LCA", name: "Saint Lucia", number: "662", phoneCode: "+1-758"},
	{code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663", phoneCode: "+590"},
	{code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666", phoneCode: "+508"},
	{code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670", phoneCode: "+1-784"},
	{code: "WS", code3: "WSM", name: "Samoa", number: "882", phoneCode: "+685"},
	{code: "SM", code3: "SMR", name: "San Marino", number: "674", phoneCode: "+378"},
	{code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678", phoneCode: "+239"},
	{code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682", phoneCode: "+966"},
	{code: "SN", code3: "SEN", name: "Senegal", number: "686", phoneCode: "+221"},
	{code: "RS", code3: "SRB", name: "Serbia", number: "688", phoneCode: "+381"},
	{code: "SC", code3: "SYC", name: "Seychelles", number: "690", phoneCode: "+248"},
	{code: "SL", code3: "SLE", name: "Sierra Leone", number: "694", phoneCode: "+232"},
	{code: "SG", code3: "SGP", name: "Singapore", number: "702", phoneCode: "+65"},
	{code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534", phoneCode: "+599"},
	{code: "SK", code3: "SVK", name: "Slovakia", number: "703", phoneCode: "+421"},
	{code: "SI", code3: "SVN", name: "Slovenia", number: "705", phoneCode: "+386"},
	{code: "SB", code3: "SLB", name: "Solomon Islands", number: "090", phoneCode: "+677"},
	{code: "SO", code3: "SOM", name: "Somalia", number: "706", phoneCode: "+252"},
	{code: "ZA", code3: "ZAF", name: "South Africa", number: "710", phoneCode: "+27"},
	{code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239", phoneCode: "+"},
	{code: "SS", code3: "SSD", name: "South Sudan", number: "728", phoneCode: "+211"},
	{code: "ES", code3: "ESP", name: "Spain", number: "724", phoneCode: "+34"},
	{code: "LK", code3: "LKA", name: "Sri Lanka", number: "144", phoneCode: "+94"},
	{code: "SD", code3: "SDN", name: "Sudan (the)", number: "729", phoneCode: "+249"},
	{code: "SR", code3: "SUR", name: "Suriname", number: "740", phoneCode: "+597"},
	{code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744", phoneCode: "+47"},
	{code: "SE", code3: "SWE", name: "Sweden", number: "752", phoneCode: "+46"},
	{code: "CH", code3: "CHE", name: "Switzerland", number: "756", phoneCode: "+41"},
	{code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760", phoneCode: "+963"},
	{code: "TW", code3: "TWN", name: "Taiwan", number: "158", phoneCode: "+886"},
	{code: "TJ", code3: "TJK", name: "Tajikistan", number: "762", phoneCode: "+992"},
	{code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834", phoneCode: "+255"},
	{code: "TH", code3: "THA", name: "Thailand", number: "764", phoneCode: "+66"},
	{code: "TL", code3: "TLS", name: "Timor-Leste", number: "626", phoneCode: "+670"},
	{code: "TG", code3: "TGO", name: "Togo", number: "768", phoneCode: "+228"},
	{code: "TK", code3: "TKL", name: "Tokelau", number: "772", phoneCode: "+690"},
	{code: "TO", code3: "TON", name: "Tonga", number: "776", phoneCode: "+676"},
	{code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780", phoneCode: "+1-868"},
	{code: "TN", code3: "TUN", name: "Tunisia", number: "788", phoneCode: "+216"},
	{code: "TR", code3: "TUR", name: "Turkey", number: "792", phoneCode: "+90"},
	{code: "TM", code3: "TKM", name: "Turkmenistan", number: "795", phoneCode: "+993"},
	{code: "TC", code3: "TCA", name: "Turks and Caicos Islands (the)", number: "796", phoneCode: "+1-649"},
	{code: "TV", code3: "TUV", name: "Tuvalu", number: "798", phoneCode: "+688"},
	{code: "UG", code3: "UGA", name: "Uganda", number: "800", phoneCode: "+256"},
	{code: "UA", code3: "UKR", name: "Ukraine", number: "804", phoneCode: "+380"},
	{code: "AE", code3: "ARE", name: "United Arab Emirates (the)", number: "784", phoneCode: "+971"},
	{code: "GB", code3: "GBR", name: "United Kingdom of Great Britain and Northern Ireland (the)", number: "826", phoneCode: "+44"},
	{code: "UM", code3: "UMI", name: "United States Minor Outlying Islands (the)", number: "581", phoneCode: "+1"},
	{code: "US", code3: "USA", name: "United States of America (the)", number: "840", phoneCode: "+1"},
	{code: "UY", code3: "URY", name: "Uruguay", number: "858", phoneCode: "+598"},
	{code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860", phoneCode: "+998"},
	{code: "VU", code3: "VUT", name: "Vanuatu", number: "548", phoneCode: "+678"},
	{code: "VE", code3: "VEN", name: "Venezuela (Bolivarian Republic of)", number: "862", phoneCode: "+58"},
	{code: "VN", code3: "VNM", name: "Viet Nam", number: "704", phoneCode: "+84"},
	{code: "VG", code3: "VGB", name: "Virgin Islands (British)", number: "092", phoneCode: "+1-284"},
	{code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", number: "850", phoneCode: "+1-340"},
	{code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876", phoneCode: "+681"},
	{code: "EH", code3: "ESH", name: "Western Sahara", number: "732", phoneCode: "+212"},
	{code: "YE", code3: "YEM", name: "Yemen", number: "887", phoneCode: "+967"},
	{code: "ZM", code3: "ZMB", name: "Zambia", number: "894", phoneCode: "+260"},
	{code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716", phoneCode: "+263"},
	{code: "AX", code3: "ALA", name: "Åland Islands", number: "248", phoneCode: "+358-18"}
]
/* eslint-enable */

export const staticPages : { text: string, value: string, type: string, slug: string }[] = [
  { text: 'Ingen', value: '1', type: '2', slug: '#' },
  { text: 'Klubliste', value: '2', type: '2', slug: 'club-list' },
  { text: 'Medlemsliste', value: '3', type: '2', slug: 'member-list' },
  { text: 'Nye medlemmer', value: '4', type: '2', slug: 'member-registration' },
  { text: 'Stævner', value: '5', type: '2', slug: 'competition-list' },
  { text: 'Arrangementer', value: '6', type: '2', slug: 'event-list' },
  { text: 'Sponsere', value: '7', type: '2', slug: 'sponsor-list' },
  { text: 'Nyheder', value: '8', type: '2', slug: 'news-archive' },
  { text: 'Ranglister', value: '9', type: '2', slug: 'ranking-main' },
  { text: 'Rangliste (herrer)', value: '10', type: '2', slug: 'ranking-male' },
  { text: 'Rangliste (damer)', value: '11', type: '2', slug: 'ranking-female' },
  { text: 'Rangliste (drenge)', value: '12', type: '2', slug: 'ranking-boys' },
  { text: 'Rangliste (piger)', value: '13', type: '2', slug: 'ranking-girls' },
  { text: 'Rangliste (paradart)', value: '14', type: '2', slug: 'ranking-para' },
  { text: 'Kalender', value: '15', type: '2', slug: 'the-calendar' },
  { text: 'Turneringsplaner', value: '16', type: '2', slug: 'tournaments' },
  { text: 'Statistik', value: '17', type: '2', slug: 'statistics' }
]

export const stringToEnumValue = <ET, T>(enumObj: ET, str: string): T =>
  (enumObj as any)[Object.keys(enumObj).filter(k => (enumObj as any)[k] === str)[0]]

class CommonFunctions {
  countryList: any

  public slugify (textToSlug : string) {
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç'
    const to = 'aaaaaeeeeiiiioooouuuunc'
    if (textToSlug !== undefined) {
      textToSlug = textToSlug
        .trim()
        .toLowerCase()
        .replaceAll('å', 'aa')
        .replaceAll('ø', 'o')
        .replaceAll('æ', 'ae')
        .replaceAll('&', 'og')
      for (let i = 0, l = from.length; i < l; i++) {
        textToSlug = textToSlug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }
      textToSlug = textToSlug
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
    }

    return textToSlug
  }

  public toDanishDateString (dateString: string, dateResultVariant = 0) : string {
    if (dateResultVariant === 1) {
      return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/').replaceAll('-', '/')
    }
    if (dateResultVariant === 2) {
      return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll('.', '/').replaceAll('-', '/')
    }
    if (dateResultVariant === 3) {
      return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit' }).replaceAll('.', '/') + '-' + new Date(dateString).toLocaleDateString('da-DK', { year: 'numeric' })
    }
    if (dateResultVariant === 4) {
      return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll('.', '/')
    }
    if (dateResultVariant === 5) {
      return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'long', day: '2-digit', month: '2-digit' }).replaceAll('.', '/') + '-' + new Date(dateString).toLocaleDateString('da-DK', { year: 'numeric' })
    }
    if (dateResultVariant === 6) {
      return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'long', day: 'numeric', month: 'long' }) + ' ' + new Date(dateString).toLocaleDateString('da-DK', { year: 'numeric' })
    }

    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public toDanishDateStringWWeekDate (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'long', day: '2-digit', month: '2-digit' }).replaceAll('.', '/')
  }

  public getHoursAndMinutes (dateString: string, UTCflag = true) : string {
    const time = new Date(dateString)
    let hours = ''
    let minutes

    if (UTCflag) {
      hours = (time.getUTCHours().toString().length === 1 ? '0' + time.getUTCHours().toString() : time.getUTCHours().toString())
      minutes = (time.getUTCMinutes().toString().length === 1 ? '0' + time.getUTCMinutes().toString() : time.getUTCMinutes().toString())
    } else {
      hours = (time.getHours().toString().length === 1 ? '0' + time.getHours().toString() : time.getHours().toString())
      minutes = (time.getMinutes().toString().length === 1 ? '0' + time.getMinutes().toString() : time.getMinutes().toString())
    }

    return hours + ':' + minutes
  }

  // Convert a datetime string from UTC to app location (Europe/Copenhagen) datetime string
  public convertFromUTCtoLocalDateTime (dateString: string, convType: number, timeZoneOffset = 2) : string {
    let retVal = ''

    if (convType === 1) {
      const tempDate = new Date(dateString)
      const offset = (tempDate.getTimezoneOffset() + (timeZoneOffset * 60)) * 60 * 1000
      tempDate.setTime(tempDate.getTime() + offset)
      retVal = tempDate.toISOString()
    } else {
      // Specifying timeZone is what causes the conversion, the rest is just formatting.
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'Europe/Copenhagen', timeZoneName: 'short' } as Intl.DateTimeFormatOptions
      const formatter = new Intl.DateTimeFormat('sv-SE', options)

      retVal = formatter.format(new Date(dateString))
    }

    return retVal
  }

  // Get normal time offset in minutes to UTC for a specified timezone (without regarding DST).
  public getTZOffset (timeZone: string) : number {
    const options: Intl.DateTimeFormatOptions = {}
    options.timeZoneName = 'shortOffset'
    options.timeZone = timeZone
    const dTF = Intl.DateTimeFormat('ia', options)
    const timeZoneName = (dTF !== undefined ? dTF?.formatToParts()?.find((i) => i.type === 'timeZoneName')?.value : '000')
    const offset = timeZoneName?.slice(3)
    if (offset === undefined || !offset) return 0

    const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/)
    if (!matchData) {
      console.log(`[getTZOffset()] Cannot parse timezone name: ${timeZoneName}`)
    } else {
      const [, sign, hour, minute] = matchData
      let result = parseInt(hour) * 60
      if (sign === '+') result *= -1
      if (minute) result += parseInt(minute)

      return result
    }

    return 0
  }

  // Get a suitable ISO date string of a date object for a specific timezone.
  public toIso (date: Date, timeZone: string) : string {
    return new Date(date).toLocaleString('sv', { timeZone }).replace(' ', 'T').replace(',', '.')
  }

  // Get the number of minutes offset to UTC for a specified year, month, day, hour, minute, second and timezone (including DST). This works for most timezone locations, with some few exceptions.
  // Example on how to use for converting from a specific timezone to UTC:
  // const testLocalDatetime = new Date('2022-04-13T12:00:00.000+00:00')
  // const offsetToUTC = CommonFunctions.getUTCOffset(testLocalDatetime.getUTCFullYear(), testLocalDatetime.getUTCMonth() + 1, testLocalDatetime.getUTCDate(), testLocalDatetime.getUTCHours(), testLocalDatetime.getUTCMinutes(), testLocalDatetime.getUTCSeconds(), 'Europe/Copenhagen')
  // const testLocalDatetimeStr = testLocalDatetime.toISOString().substring(0, 23) + (offsetToUTC >= 0 ? '+' : '') + Math.trunc(offsetToUTC / 60).toString().padStart(2, '0') + ':' + (offsetToUTC % 60).toString().padStart(2, '0')
  // console.log('Test time normal datetime string = ' + testLocalDatetimeStr)
  // console.log('Test time converted to UTC ISO datetime = ' + new Date(testLocalDatetimeStr).toISOString())
  // -
  public getUTCOffset (year: number, month: number, day: number, hour: number, minute: number, second: number, timeZone: string) : number {
    const date = [year, month, day].map(e => (e + '').padStart(2, '0')).join('-') + 'T' + [hour, minute, second].map(e => (e + '').padStart(2, '0')).join(':')
    const dt = Date.parse(date + 'Z')

    for (let offsetMinutes = -900; offsetMinutes <= 900; offsetMinutes += 15) {
      const test = new Date(dt - offsetMinutes * 60000)

      if (date === this.toIso(test, timeZone)) {
        return offsetMinutes
      }
    }

    return 0
  }

  // Get an information object that contains information about the normal time offset (in minutes) to UTC and the DST (Daylight Savings Time) offset (in minutes) to UTC for a specified timezone and year.
  public getDSTDetails (zone: string, year: number) {
    const jan = this.getUTCOffset(year, 1, 1, 0, 0, 0, zone)
    const jul = this.getUTCOffset(year, 7, 1, 0, 0, 0, zone)

    if (jan === jul) return { zone, year, standardOffsetMinutes: Math.min(jan, jul) }

    return { zone, year, standardOffsetMinutes: Math.min(jan, jul), dstOffsetMinutes: Math.max(jan, jul), dstChangeMinutes: Math.abs(jan - jul) }
  }

  public strongPasswordTest (password: string) : boolean {
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    if (strongPassword.test(password)) {
      return true
    }
    return false
  }

  public scrollToTop (top = 0, left = 0) : void {
    window.scrollTo({
      top: top,
      left: left,
      behavior: 'smooth'
    })
  }

  public getLastYearDateString (date = '') : string {
    const d = new Date(date)
    d.setDate(d.getDate() - 365)

    return d.toISOString().split('T')[0]
  }

  // public navigationRouter (url : string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') {
  //   console.log('URL = ' + url + ' Optional ' + optionalPath1 + ' ' + optionalPath2 + ' ' + optionalPath3)
  //   if (url.toLowerCase().includes('http') || url.toLowerCase().includes('www')) {
  //     window.open(url, 'noopener')
  //     return url
  //   }
  //   if (url === '#') {
  //     return url
  //   }
  //   if (url === 'club-list') {
  //     this.$router.push('/klubber')
  //     return url
  //   }
  //   if (url === 'member-list') {
  //     this.$router.push('/medlemmer')
  //     return url
  //   }
  //   if (url === 'competition-list') {
  //     this.$router.push('/staevner')
  //     return url
  //   }
  //   if (url === 'news-archive') {
  //     this.$router.push('/nyheder')
  //     return url
  //   } else {
  //     console.log(url)
  //     this.$router.push({ name: 'Pages', params: { pathSlug1: optionalPath1, pathSlug2: optionalPath2, pathSlug3: optionalPath3, slug: url } })
  //     return url
  //   }
  // }
  public navigationRouter (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : void {
    // console.log(url)

    if (url.toLowerCase().trim().includes('http') || url.toLowerCase().trim().includes('www')) {
      window.open(url, 'noopener', '_blank')
      return
    }
    if (url === '#') {
      return
    }
    if (url === 'club-list') {
      router.push('/klubber')
      return
    }
    if (url === 'member-list') {
      router.push('/medlemmer')
      return
    }
    if (url === 'member-registration') {
      router.push('/medlemmer/nyt-medlem')
      return
    }
    if (url === 'competition-list') {
      router.push('/staevner')
      return
    }
    if (url === 'event-list') {
      router.push('/arrangementer')
      return
    }
    if (url === 'sponsor-list') {
      router.push('/sponsorer')
      return
    }
    if (url === 'news-archive') {
      router.push('/nyheder')
      return
    }
    if (url === 'ranking-main') {
      router.push({ path: '/staevner/ranglister' })
      return
    }
    if (url === 'ranking-male') {
      router.push({ name: 'Rankinglist', params: { slug: 'herrer' } })
      return
    }
    if (url === 'raking-female') {
      router.push({ name: 'Rankinglist', params: { slug: 'damer' } })
      return
    }
    if (url === 'ranking-boys') {
      router.push({ name: 'Rankinglist', params: { slug: 'drenge' } })
      return
    }
    if (url === 'ranking-girls') {
      router.push({ name: 'Rankinglist', params: { slug: 'piger' } })
      return
    }
    if (url === 'ranking-para') {
      router.push({ name: 'Rankinglist', params: { slug: 'paradart' } })
      return
    }
    if (url === 'the-calendar') {
      router.push({ path: '/kalender' })
      return
    }
    if (url === 'turneringslivekampe') {
      router.push({ name: 'LiveMatchScheduleList' })
      return
    }
    if (url === 'tournaments') {
      router.push({ name: 'FrontEndTournaments' })
      return
    }
    if (url === 'statistics') {
      router.push({ name: 'Statistics' })
      return
    }
    router.push({ name: 'Pages', params: { pathSlug1: this.slugify(optionalPath1), pathSlug2: this.slugify(optionalPath2), pathSlug3: this.slugify(optionalPath3), slug: url } })
  }

  public generatePassword (passwordLength = 8) : string {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let password = ''
    for (let i = 0; i < passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length)
      password += chars.substring(randomNumber, randomNumber + 1)
    }
    return password
  }

  public redirectLogin () : void {
    router.push({ path: '/medlemsportal/login' })
  }
}

export default new CommonFunctions()
