import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Posts extends APIBaseFunctions {
  static PostsDataService = class {
    private static cachedPostsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/indlaegs?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/indlaegs?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static getBySlug (postSlug = '') {
      return http.get(`/indlaegs?indlaeg_slug=${postSlug}`)
    }

    static get (id: string) {
      return http.get(`/indlaegs/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/indlaegs/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static getNewsCount () {
      return http.get('/indlaegs/count?indlaeg_status_eq=true&indlaeg_type_eq=0')
    }

    static create (data: any) {
      return http.post('/indlaegs', data)
    }

    static update (id: string, data: any) {
      return http.put(`/indlaegs/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/indlaegs/${id}`)
    }

    static deleteAll () {
      return http.delete('/indlaegs')
    }

    static findByPostStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/indlaegs?indlaeg_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findByPostYear (status: boolean, ignoreStatus: boolean, year: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }
      if (ignoreStatus) {
        retVal = http.get(`/indlaegs?indlaeg_udgivet_contains=${year}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/indlaegs?indlaeg_status=${status}&indlaeg_udgivet_contains=${year}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
      return retVal
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        // Handle telephone number in search term
        if (ignoreStatus) {
          retVal = http.get(`/indlaegs?indlaeg_forfatter.phone_contains=${searchTerm.trim()}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/indlaegs?indlaeg_status=${status}&indlaeg_forfatter.phone_contains=${searchTerm.trim()}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else if (searchTerm.indexOf('@') >= 0) {
        // Handle email address in search term
        if (ignoreStatus) {
          retVal = http.get(`/indlaegs?indlaeg_forfatter.email_contains=${searchTerm.trim()}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/indlaegs?indlaeg_status=${status}&indlaeg_forfatter.email_contains=${searchTerm.trim()}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        // Do a search in names for the search term
        if (ignoreStatus) {
          retVal = http.get(`/indlaegs?_where[_or][0][indlaeg_forfatter.username_contains]=${searchTerm.trim()}&_where[_or][1][indlaeg_versionerings_id.indlaeg_versionering_titel_contains]=${searchTerm}&_where[_or][2][indlaeg_versionerings_id.indlaeg_versionering_indhold_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/indlaegs?indlaeg_status=${status}&_where[_or][0][indlaeg_forfatter.username_contains]=${searchTerm.trim()}&_where[_or][1][indlaeg_versionerings_id.indlaeg_versionering_titel_contains]=${searchTerm}&_where[_or][2][indlaeg_versionerings_id.indlaeg_versionering_indhold_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }

      return retVal
    }
  }

  static PostVersionsDataService = class {
    private static cachedPostVersionsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostVersionsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/indlaeg-versionerings?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/indlaeg-versionerings?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/indlaeg-versionerings/${id}`)
    }

    static getCount () {
      return http.get('/indlaeg-versionerings/count')
    }

    static create (data: any) {
      return http.post('/indlaeg-versionerings', data)
    }

    static update (id: string, data: any) {
      return http.put(`/indlaeg-versionerings/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/indlaeg-versionerings/${id}`)
    }

    static deleteAll () {
      return http.delete('/indlaeg-versionerings')
    }
  }

  static PostCategoriesDataService = class {
    private static cachedPostCategoriesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Posts.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedPostCategoriesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/indlaeg-kategoris?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/indlaeg-kategoris?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/indlaeg-kategoris/${id}`)
    }

    static getCount () {
      return http.get('/indlaeg-kategoris/count')
    }

    static create (data: any) {
      return http.post('/indlaeg-kategoris', data)
    }

    static update (id: string, data: any) {
      return http.put(`/indlaeg-kategoris/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/indlaeg-kategoris/${id}`)
    }

    static deleteAll () {
      return http.delete('/indlaeg-kategoris')
    }
  }
}

export default new Posts()
