import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class MembersDataService extends APIBaseFunctions {
  private cachedMembersDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = MembersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMembersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/medlems?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/medlems?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = MembersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMembersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/medlems?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/medlems?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/medlems/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/medlems/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/medlems', data)
  }

  update (id: string, data: any) {
    return http.put(`/medlems/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/medlems/${id}`)
  }

  deleteAll () {
    return http.delete('/medlems')
  }

  findByUserStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = MembersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMembersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/medlems?medlem_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findByNameAndLicenseSearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = MembersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMembersDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    const searchArray = searchTerm.trim().split(' ')

    if (searchArray.length > 1) {
      // retVal = http.get(`/medlems?_where[_or][0][medlem_licens_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}&_where[_or][3][0][bruger_id.bruger_fornavn_contains]=${searchArray[0]}&_where[_or][3][1][bruger_id.bruger_efternavn_contains]=${searchArray[searchArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      retVal = http.get(`/medlems?_where[_or][0][medlem_licens_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}&_where[_or][3][0][user_id.firstname_contains]=${searchArray[0]}&_where[_or][3][1][user_id.lastname_contains]=${searchArray[searchArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    } else {
      // retVal = http.get(`/medlems?_where[_or][0][medlem_licens_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      retVal = http.get(`/medlems?_where[_or][0][medlem_licens_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return retVal
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = MembersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMembersDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (!isNaN(Number(searchTerm))) {
      // Handle telephone number and license number in search term
      if (ignoreStatus) {
        // retVal = http.get(`/medlems?_where[_or][0][bruger_id.bruger_telefon_contains]=${searchTerm}&_where[_or][1][medlem_licens]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/medlems?_where[_or][0][user_id.phone_contains]=${searchTerm}&_where[_or][1][medlem_licens]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.bruger_telefon_contains]=${searchTerm}&_where[_or][1][medlem_licens]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][user_id.phone_contains]=${searchTerm}&_where[_or][1][medlem_licens]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    } else if (searchTerm.indexOf('@') >= 0) {
      // Handle email address in search term
      if (ignoreStatus) {
        // retVal = http.get(`/medlems?user_id.bruger_email_contains=${searchTerm}${sortingOrderParameter}`)
        retVal = http.get(`/medlems?user_id.email_contains=${searchTerm}${sortingOrderParameter}`)
      } else {
        // retVal = http.get(`/medlems?medlem_status=${status}&user_id.bruger_email_contains=${searchTerm}${sortingOrderParameter}`)
        retVal = http.get(`/medlems?medlem_status=${status}&user_id.email_contains=${searchTerm}${sortingOrderParameter}`)
      }
    } else {
      const searchTermArray = searchTerm.trim().split(' ')

      if (searchTermArray.length > 1) {
        // Do a special search in names for the search term where the first part of the of the search term that is seperated with a space is considered as the first name, and the last part of the search term, that is seperated with a space is considered as the last name
        if (ignoreStatus) {
          // retVal = http.get(`/medlems?_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}&_where[_or][3][bruger_id.bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][4][bruger_id.bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}`)
          // retVal = http.get(`/medlems?_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}&_where[_or][3][0][bruger_id.bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_id.bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          // retVal = http.get(`/medlems?_where[_or][0][bruger_id.username_contains]=${searchTerm}&_where[_or][1][bruger_id.firstname_contains]=${searchTerm}&_where[_or][2][bruger_id.lastname_contains]=${searchTerm}&_where[_or][3][0][bruger_id.firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_id.lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/medlems?_where[_or][0][user_id.username_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}&_where[_or][3][0][user_id.firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][user_id.lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}&_where[_or][3][bruger_id.bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][4][bruger_id.bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}`)
          // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}&_where[_or][3][0][bruger_id.bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_id.bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.username_contains]=${searchTerm}&_where[_or][1][bruger_id.firstname_contains]=${searchTerm}&_where[_or][2][bruger_id.lastname_contains]=${searchTerm}&_where[_or][3][0][bruger_id.firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_id.lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][user_id.username_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}&_where[_or][3][0][user_id.firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][user_id.lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        // Do a normal search in names for the search term
        if (ignoreStatus) {
          // retVal = http.get(`/medlems?_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          // retVal = http.get(`/medlems?_where[_or][0][bruger_id.username_contains]=${searchTerm}&_where[_or][1][bruger_id.firstname_contains]=${searchTerm}&_where[_or][2][bruger_id.lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/medlems?_where[_or][0][user_id.username_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_id.bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_id.bruger_efternavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          // retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][bruger_id.username_contains]=${searchTerm}&_where[_or][1][bruger_id.firstname_contains]=${searchTerm}&_where[_or][2][bruger_id.lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/medlems?medlem_status=${status}&_where[_or][0][user_id.username_contains]=${searchTerm}&_where[_or][1][user_id.firstname_contains]=${searchTerm}&_where[_or][2][user_id.lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }
    }

    return retVal
  }
}

export default new MembersDataService()
