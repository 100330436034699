import http from '../http-common'
import { APIBaseFunctions } from '@/components/Utility/APIBase'

export class Chat extends APIBaseFunctions {
  static ChatDataService = class {
    static create (data: any) {
      return http.post('/chat', data)
    }
  }
}

export default new Chat()
