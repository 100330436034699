import { Vue, Options } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { Chat } from '@/services/ChatService'

@Options({
})

export default class chatBot extends Vue {
    isInitialDone = false
    widget = false
    chats: any = []
    welcomeMessage: any = 'Hejsa, jeg er Dansk Dart Unions Chatbot.\nJeg er stadig meget ny indenfor dart, så det er endnu ikke alt jeg ved, lige nu kan du spørge mig om ting der omhandler reglementer.\nHvis jeg ikke kan besvare dit spørgsmål, så kan jeg nok hjælpe dig med, hvor du finder det rigtige svar.'
    faqQuestions: any = ['Skal jeg sidde over før jeg må rykke ned på lavere rangerende hold i turneringen?', 'Må jeg stille op i C-rækken til et stævne?', 'Hvor gammel skal man være for at må spille med i seniorturneringen?', 'Hvor sent kan jeg melde afbud til en turneringskamp?', 'Hvad er reglerne for påklædning når jeg spiller dart?']
    userQuestion = ''
    chatLoading = false

    public widgetManageFunction () : void {
      this.isInitialDone = true
      this.widget = !this.widget
      setTimeout(() => {
        this.scrollToBottom()
      }, 10)
    }

    public isUserQuestionEmpty () : boolean {
      const inputQuestion = this.userQuestion.trim()
      if (inputQuestion.length > 0) {
        return false
      } else {
        return true
      }
    }

    public async sendTheQuestionToAssistant () : Promise<void> {
      this.userQuestion = this.userQuestion.trim()
      this.chatLoading = true
      if (this.userQuestion.length > 0) {
        this.chats.push({ q: this.userQuestion })
        await Chat.ChatDataService.create({ query: this.userQuestion })
          .then((response) => {
            const data = response.data
            this.chatLoading = false
            const ansChatbot = {
              a: data.assistant
            }
            this.chats.push(ansChatbot)
          })
          .catch((error) => {
            console.log('ERROR', error)
            const ansChatbot = {
              a: 'Please try later'
            }
            this.chats.push(ansChatbot)
          })
        this.userQuestion = ''
      }
    }

    public scrollToBottom () : void {
      const element = document.getElementById('chat-container')
      if (element) {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth'
        })
      }
    }

    public setFaqQuestion (selectedIndex: any) : void {
      this.userQuestion = this.faqQuestions[selectedIndex]
      this.sendTheQuestionToAssistant()
      this.faqQuestions.splice(selectedIndex, 1)
    }

    @Watch('chats', { deep: true })
    onChateUpdates () : void {
      sessionStorage.setItem('myChats', JSON.stringify(this.chats))
      setTimeout(() => {
        this.scrollToBottom()
      }, 10)
    }

    async mounted (): Promise<void> {
      const storedChats = sessionStorage.getItem('myChats')
      if (storedChats) {
        this.chats = JSON.parse(storedChats)
      }
    }
}
